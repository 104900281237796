










































import { Vue, Component, Prop } from "vue-property-decorator";

type Contact = {
  name: '',
  title: '',
  phone: '',
  email: '',
  isPrimary: false,
  notes: ''
}

@Component
export default class extends Vue {
  @Prop() contacts!: Array<Contact>;
  @Prop() index!: number;
  @Prop() showEdit!: boolean;

  data = {} as any;
  remove: boolean = false;
  dialog: boolean = false;
  valid: boolean = false;
  emailRule = [
    (v: any) => {
      if(v.length > 0) {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(v) || 'Invalid e-mail.';
      } else {
        return true;
      }
    },
  ];

  doRemove() {
    this.$emit('remove', this.index);
  }

  openDialog() {
    this.data = this.contacts[this.index];
    this.dialog = true;
  }

  closeDialog() {
    this.dialog = false;
  }

  checkIfPrimaryExists() {
    let firstExists = false;
    this.contacts.forEach((element, index: number) => {
      if (element.isPrimary && this.index !== index) {
        firstExists = true;
      }
    });

    return firstExists;
  }

  save() {
    (this.$refs.contactForm as Vue & { validate: () => boolean }).validate();
    if (this.valid) {
      this.dialog = false;
      this.remove = false;
    }
  }
}
