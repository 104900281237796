






























import { Vue, Component, Prop } from "vue-property-decorator";

type Container = {
  id: string,
  availableCount: number
}

type AllContainers = {
  id: string,
  name: string,
  size: string,
  image_url: string
}

@Component
export default class extends Vue {
  @Prop() locationContainers!: Container[];
  @Prop() allContainers!: AllContainers[];

  dialog: boolean = false;

  get containers() {
    if (this.dialog) {
      const data = this.allContainers.map( dbContainer => {
        const locationContainer = this.locationContainers.find( container => container.id === dbContainer.id);
        if (locationContainer) {
          return { ...dbContainer, availableCount: locationContainer.availableCount };
        } else {
          return { ...dbContainer, availableCount: 0 };
        }
      });
      return data;
    }
  }

  get containersList() {
    const data = this.allContainers.map( dbContainer => {
      const locationContainer = this.locationContainers.find( container => container.id === dbContainer.id);
      if (locationContainer) {
        return { ...dbContainer, availableCount: locationContainer.availableCount };
      }
    });
    return data.filter(Boolean);
  }

  get show() {
    return this.locationContainers && this.locationContainers.length
  }

  openDialog() {
    this.dialog = true;
  }

  closeDialog() {
    this.$forceUpdate();
    this.dialog = false;
  }

  confirm() {
    const newContainers = this.containers!.filter(container =>{ 
      if (container.availableCount && container.availableCount > 0)
      return container; 
    }).map(container => { return { id: container.id, availableCount: container.availableCount } });
    this.$emit('confirm', newContainers);
    this.closeDialog();
  }
}
